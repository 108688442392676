import './index.module.scss';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import ContactInfoProps from '../../../components/contact-info/contact-info';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ContentInner from '../../../components/content-inner/content-inner';
import ContactForm from '../../../components/contact-form/contact-form';
import dollarCoin from '../../../assets/images/dollar-coin.svg';
import diamondIcon from '../../../assets/images/diamond-icon.svg';
import vasIcon from '../../../assets/images/vas.svg';
import umbrellaIcon from '../../../assets/images/umbrella-icon.svg';
import banner from '../../../assets/images/services-banner.jpg';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={banner}
        caption={
          <h2 className="x-large-heading color-white">
            Bespoke Wealth Management Solutions for Businesses
          </h2>
        }
      />
      <ContentInner
        heading="Concierge Support & Resources for Businesses."
        content="We know what running a business can be like-you have to wear a lot of hats. We provide support for business people 24/7/365, because we know that business never sleeps. We will collaborate with both our trusted partners and in-house experts to provide you with any needed resources to help your business succeed. From employee benefits, business valuation and potential customers to legal services. And when it comes time to develop an exit strategy, our processes can present you with options to help you make the most of your life’s work. "
        subheading="We offer integrated personal and business financial strategies that include:"
        subContent={
          <div>
            <a
              href={
                '/our-services/services-for-businesses/retirement-plan-services'
              }
              className="small-heading color-theme icon-small mar-top-rem "
            >
              {' '}
              <i className="icon-head">
                <img src={dollarCoin} alt="coin icon" />
              </i>{' '}
              Retirement Plan Services{' '}
            </a>{' '}
            <p className="norma-size-font">
              Whether you are an employer or an employee, we can help make your
              401(k) or 403(b) retirement plan simpler, cheaper, and easier to
              manage. We can also provide individual management for your
              workers’ 401k plans.
            </p>
            <a
              href={
                '/our-services/services-for-businesses/business-succession-planning'
              }
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={diamondIcon} alt="coin icon" />
              </i>{' '}
              Business Succession Planning{' '}
            </a>
            <p className="norma-size-font">
              The principles and clients of DGM have founded, acquired and sold
              numerous businesses. We can help you identify and implement
              successful succession strategies to maximize returns and minimize
              taxes.
            </p>
            <a
              href={
                '/our-services/services-for-businesses/institutional-cash-management'
              }
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={vasIcon} alt="coin icon" />
              </i>{' '}
              Institutional Cash Management{' '}
            </a>
            <p className="norma-size-font">
              Our team can provide fixed income opportunities for businesses and
              institutions alike, featuring individualized investment solutions
              and cash management strategies to fit each individual objective.
            </p>
            <a
              href={
                '/our-services/services-for-businesses/bespoke-business-services'
              }
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={umbrellaIcon} alt="coin icon" />
              </i>{' '}
              Bespoke Business Services{' '}
            </a>
            <p className="norma-size-font">
              Our firm and its business partners can aid you with mergers and
              acquisitions, valuations, accounting and planning to implement
              mergers, sales and acquisitions to help meet your specific
              objectives.
            </p>
          </div>
        }
      />
      <ContactForm />
      <ContactInfoProps />
      <Footer />
    </Layout>
  );
}

export default Index;
